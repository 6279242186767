@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Space+Mono:wght@400;700&display=swap');

body {
    font-family: 'Space Mono', 'Courier New', Courier, monospace
}

.app-header {
    text-align: center;
    background: #000;
    color: #407A33;
    font-family: 'Bungee', sans-serif;
    font-size: 19px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5rem;
}

.app-header .header-item {
    width: 33.3%;
}

.app-header .header-item:first-child {
    text-align: left;
}

.app-header .header-item:last-child > * {
    text-align: right;
}

.app-header h3 {
    margin: 0;
}

.app-header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.app-header-right p, .app-header-right a {
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
    font-family: 'Space Mono', 'Courier New', Courier, monospace;
    font-size: 17px;
}

.app-header p:hover, .app-header a:hover {
    color: #00f001;
}

.app-header p {
    margin-right: 10px;
}

@media only screen and (max-width: 768px) {

    .app-header .header-item:first-child {
        display: none
    }

    .app-header {
        flex-direction: column;
        height: fit-content;
        padding: 10px;
    }

    .app-header .header-item{
        width: 100%;
    }

    .app-header h3 {
        font-size: unset;
        
    }

    .app-header-right {
        justify-content: center;
    }

    .app-header-right p, .app-header-right a {
        font-size: 16px;
    }
}