:root {
    --green: #00f001;
    --light-green: #80f880;
    --dark-green: #001800;
}

.home {
    display: flex;
    height: calc(100vh - 45px); /* 45px is header height */
    align-items: center;
}

.home-image, .home-map {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    height: 100%;
}

.home-image-image {
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: center;
}

.home-description-modal-active {
    flex-direction: column;
    justify-content: space-between;
}

.home-image {
    border-right: 1px solid #ccd5d0;
}

.home-image img {
    max-width: 100%;
}

.home-description-modal {
    background: #fff;
    text-align: left;
    height: 50%;
    width: calc(100% - 2rem);
    padding: 1rem;
    overflow: scroll;
}

.home-description-modal button {
    background: none;
    border: none;
    font-weight: bold;
    font-size: 36px;
    cursor: pointer;
    color: var(--green);
    float: right;
}

.home-description-modal .awardee-details > * {
    margin: 5px 0;
}

.home-description-modal .awardee-details h1 {
    color: var(--green);
    font-size: 48px;
    letter-spacing: 2px;
}

.home-description-modal .awardee-details h3 {
    color: #333;
}

.home-description-modal .awardee-details a {
    color: var(--green);
    display: inline-block;
    width: 100%;
    margin: 2px 0;
}

.home-description-modal .awardee-details p {
    line-height: 1.65em;
    margin: 10px 0;
    text-align: justify;
    width: 95%;
}

.marker, .marker-text {
    display: none;
    cursor: pointer;
}

#image-map area {
    cursor: url('../images/cursor.png') 10 20, auto;
}

.home-map {
    flex-direction: column;
    justify-content: center;
}

.home-map .vibe-count {
    color: #fff;
    font-size: 18px;
    text-align: right;
    margin: 0;
    margin-right: 20px;
    position: absolute;
    top: 75px;
    right: 0;
    background: linear-gradient(144deg, #e84393, #4834d4);
    padding: 5px 7.5px;
    border-radius: 10px;
    box-shadow: 2.5px 2px 4px rgba(0,0,0,0.25);
    -webkit-animation: Vibrations 20s ease infinite;
    -moz-animation: Vibrations 20s ease infinite;
    animation: Vibrations 20s ease infinite;
}

#vibe-count-complete {
    color: #fff;
    text-align: center;
    animation: vibe-count-complete 7s ease forwards;
    position: absolute;
    z-index: 2;
    background: linear-gradient(144deg, #e84393, #4834d4);
    padding: 1rem;
    border: 1px solid #fff;
    border-radius: 10px;
    opacity: 0.9
}

#vibe-count-complete h1 {
    font-family: 'Bungee', sans-serif;
}



/* CSS Gradient */

.home {
    background: linear-gradient(216deg, #e84393, #4834d4, #fbc531, #f7f4ec);
    background-size: 800% 800%;

    -webkit-animation: Vibrations 20s ease infinite;
    -moz-animation: Vibrations 20s ease infinite;
    animation: Vibrations 20s ease infinite;
}

@-webkit-keyframes Vibrations {
    0%{background-position:15% 0%}
    50%{background-position:86% 100%}
    100%{background-position:15% 0%}
}
@-moz-keyframes Vibrations {
    0%{background-position:15% 0%}
    50%{background-position:86% 100%}
    100%{background-position:15% 0%}
}
@keyframes Vibrations {
    0%{background-position:15% 0%}
    50%{background-position:86% 100%}
    100%{background-position:15% 0%}
}

@-webkit-keyframes vibe-count-complete {
    0% {opacity: 0}
    50% {opacity: 1}
    100% {opacity: 0}
}
@-moz-keyframes vibe-count-complete {
    0% {opacity: 0}
    50% {opacity: 1}
    100% {opacity: 0}
}
@keyframes vibe-count-complete {
    0% {opacity: 0}
    50% {opacity: 1}
    100% {opacity: 0}
}

@media only screen and (max-width: 768px) {

    .home {
        flex-direction: column;
        height: unset;
    }

    .home-image {
        flex-basis: 25%;
    }

    .home-map {
        flex-basis: 75%;
    }

    .home-image-image, .home-description-modal {
        height: 100%
    }

    .home-description-modal {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.85);
        z-index: 1;
    }

    .home-image {
        border-right: unset;
    }

    .home-map .vibe-count {
        position: relative;
        top: 0;
        text-align: center;
        width: 100%;
        font-size: 16px;
        margin-top: 1rem;
        background: none;
        box-shadow: none;
    }
}